import * as PIA from "../actions/proInvoicesActions";

import { APP_STATE_COMPLETE_RESET } from "../../../AppState/actions/appStateActions";

import handleReducerActions from "../../../../core/handleReducerActions";
import { PROINVOICE_UNPAID } from "../ProInvoicesStatus/ProInvoicesStatus";

const initialState = {
  all: [],
  paging: {
    limit: 10,
    current_page: 0,
    next_page: 0,
    previous_page: 0,
    total_pages: 0,
    total: 0
  },
  filters: {
    status: "",
    keyword: "",
    date_created_range: "",
    date_due_range: "",
    date_paid_on_range: "",
    payer: ""
  },
  sort: {
    sort_by: "", // Remove magic variable implementation
    order_by: ""
  },
  statusCollection: [],
  isFetching: false,
  isFetchingFilters: false,
  proinvoicesForWidget: [],
  isFetchingProInvoicesForWidget: false,
  isPerformingMasspay: false
};

const proinvoicesStateHandlers = {
  [PIA.PROINVOICES_CLEAR_FILTERS]: proinvoicesClearFitlers,
  [PIA.PROINVOICES_REQUEST]: proinvoicesRequest,
  [PIA.PROINVOICES_REQUEST_STATUS_COLLECTION]: proinvoicesRequestStatusCollection,
  [PIA.PROINVOICES_RECEIVE_STATUS_COLLECTION]: proinvoicesReceiveStatusCollection,
  [PIA.PROINVOICES_RECEIVE]: proinvoicesReceive,
  [PIA.PROINVOICES_UPDATE_PROINVOICE_PAYER]: proinvoicesUpdateProInvoicePayer,
  [PIA.PROINVOICES_LIST_SELECT_TABLE_ROW]: proinvoicesListSelectTableRow,
  [PIA.PROINVOICES_LIST_DESELECT_TABLE_ROW]: proinvoicesListDeselectTableRow,
  [PIA.PROINVOICES_LIST_SELECT_ALL_TABLE_ROWS]: proinvoicesListSelectAllTableRows,
  [PIA.PROINVOICES_LIST_DESELECT_ALL_TABLE_ROWS]: proinvoicesListDeselectAllTableRows,
  [PIA.PROINVOICES_FOR_WIDGET_REQUEST]: proinvoicesForWidgetRequest,
  [PIA.PROINVOICES_FOR_WIDGET_RECEIVE]: proinvoicesForWidgetReceive,
  [PIA.PROINVOICES_MASS_PAY_REQUEST]: proinvoicesRequestMassPay,
  [PIA.PROINVOICES_MASS_PAY_RECEIVE]: proinvoicesReceiveMassPay,
  [PIA.PROINVOICES_CANCEL_REQUEST]: proinvoicesCancelRequest,
  [PIA.PROINVOICES_CANCEL_RECEIVE]: proinvoicesCancelReceive,
  [APP_STATE_COMPLETE_RESET]: resetState
};

// this is the actual reducer function
export default function(state = initialState, action) {
  return handleReducerActions(proinvoicesStateHandlers, state, action);
}

function proinvoicesForWidgetRequest(state, action) {
  return {
    ...state,
    isFetchingProInvoicesForWidget: true
  };
}

function proinvoicesForWidgetReceive(state, action) {
  return {
    ...state,
    isFetchingProInvoicesForWidget: false,
    proinvoicesForWidget: action.payload
  };
}

function proinvoicesRequestMassPay(state, action) {
  return {
    ...state,
    isPerformingMasspay: true
  };
}

function proinvoicesReceiveMassPay(state, action) {
  return {
    ...state,
    isPerformingMasspay: false
  };
}

function proinvoicesRequest(state, action) {
  return {
    ...state,
    isFetching: true
  };
}

function proinvoicesClearFitlers(state) {
  return {
    ...state,
    filters: {
      status: "",
      keyword: "",
      date_created_range: "",
      date_due_range: "",
      date_paid_on_range: "",
      payer: ""
    }
  };
}

function proinvoicesRequestStatusCollection(state, action) {
  return {
    ...state,
    isFetchingFilters: false
  };
}

function proinvoicesReceive(state, action) {
  return {
    ...state,
    all: action.payload.proinvoices,
    paging: action.payload.paging,
    filters: action.payload.filters,
    sort: action.payload.sort,
    isFetching: false
  };
}

function proinvoicesReceiveStatusCollection(state, action) {
  return {
    ...state,
    statusCollection: action.payload.statuses,
    isFetchingFilters: false
  };
}

function proinvoicesUpdateProInvoicePayer(state, action) {
  const { proinvoice, payer } = action.payload;
  return {
    ...state,
    all: state.all.map(currentProInvoice => {
      if (proinvoice.id !== currentProInvoice.id) return currentProInvoice;
      return {
        ...currentProInvoice,
        invoice_to: {
          ...payer
        }
      };
    })
  };
}

function proinvoicesListSelectTableRow(state, action) {
  return {
    ...state,
    all: state.all.map(proInvoice => {
      return proInvoice.id === action.payload.id
        ? { ...proInvoice, selected: true }
        : proInvoice;
    })
  };
}

function proinvoicesListDeselectTableRow(state, action) {
  return {
    ...state,
    all: state.all.map(proInvoice => {
      return proInvoice.id === action.payload.id
        ? { ...proInvoice, selected: false }
        : proInvoice;
    })
  };
}

function proinvoicesListSelectAllTableRows(state, action) {
  return {
    ...state,
    all: state.all.map(proInvoice => {
      return { 
        ...proInvoice, 
        selected: proInvoice.status.code !== "paid" 
      };
    })
  };
}


function proinvoicesListDeselectAllTableRows(state, action) {
  return {
    ...state,
    all: state.all.map(proInvoice => {
      return { ...proInvoice, selected: false };
    })
  };
}

function proinvoicesCancelRequest(state) {
  return { ...state };
}

function proinvoicesCancelReceive(state, action) {
  return {
    ...state,
    all: state.all.map(proinvoice => {
      if (proinvoice.id !== action.payload.id) {
        return {
          ...proinvoice
        };
      }
      return {
        ...proinvoice,
        ...action.payload
      };
    })
  };
}

function resetState(state) {
  return { ...initialState };
}

// this is a selector
export function getAllProInvoices(state) {
  return state.proinvoices.all;
}

export function getProInvoicesPaging(state) {
  return state.proinvoices.paging;
}

export function getProInvoicesSort(state) {
  return state.proinvoices.sort;
}

export function getProInvoicesFilters(state) {
  return state.proinvoices.filters;
}

export function getProInvoicesStatusCollection(state) {
  return state.proinvoices.statusCollection;
}

export function isFetchingProInvoices(state) {
  return state.proinvoices.isFetching;
}

export function isFetchingProInvoicesFilters(state) {
  return state.proinvoices.isFetchingFilters;
}

export function getProInvoicesListSelectedRowsCount(state) {
  const rows = state.proinvoices.all.filter(item => item.selected);
  return rows ? rows.length : 0;
}

export function getProInvoicesSelectedRowsIds(state) {
  const rows = state.proinvoices.all.filter(item => item.selected);

  return rows.map(row => row.id);
}

export function getAllUnpaidIds(state) {
  const rows = state.proinvoices.all.filter(
    item => item.status.code === PROINVOICE_UNPAID
  );

  return rows.map(row => row.id);
}

export function getProInvoicesForWidget(state) {
  return state.proinvoices.proinvoicesForWidget;
}

export function isFetchingProInvoicesForWidget(state) {
  return state.proinvoices.isFetchingProInvoicesForWidget;
}

export function isPerformingMasspay(state) {
  return state.proinvoices.isPerformingMasspay;
}
